import type { ColorKeys } from "./types";

export const colorScheme: Record<ColorKeys | "inherit", string> = {
  primary: "!colorScheme-primary",
  secondary: "!colorScheme-secondary",
  tertiary: "!colorScheme-tertiary",
  danger: "!colorScheme-danger",
  success: "!colorScheme-success",
  warning: "!colorScheme-warning",
  info: "!colorScheme-info",
  gray: "!colorScheme-gray",
  inherit: "!colorScheme-inherit",
};

export type TColorScheme = keyof typeof colorScheme;

export const emphasize = {
  weak: "font-emphasize-weak",
  strong: "font-emphasize-strong",
  none: "font-emphasize-none",
};

export const shadow = {
  none: "shadow-none",
  sm: "shadow-sm",
  md: "shadow-md",
  lg: "shadow-lg",
};
